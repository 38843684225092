<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Link gerado com sucesso!</p>
      </header>
      <section class="modal-card-body">
        <div class="link">
          <app-input v-model="url"> </app-input>
          <b-button @click="copyLink" type="is-primary"> Copiar </b-button>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', true)">Fechar</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
  },

  methods: {
    copyLink() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = this.url;

      input.focus();
      input.select();

      if (document.execCommand('copy')) {
        this.$buefy.snackbar.open({
          message: 'Link copiado com sucesso!',
          type: 'is-success',
        });

        this.$emit('close', true);
      }

      document.body.removeChild(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-transfer-modal {
  .modal-card {
    width: 600px;

    .modal-card-body {
      .link {
        font-size: 0.9rem;

        display: flex;
        justify-content: space-between;

        .field {
          width: 85%;
        }
      }
    }
  }
}
</style>
