<template>
  <div class="schedules-form-view">
    <app-header icon="clipboard" title="Nova Consulta" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-6">
                <app-autocomplete
                  label="Paciente"
                  placeholder="Digite o Nome ou o CPF"
                  v-model="schedule.patient_id"
                  :getter="patientsGetter"
                  :setter="patientSetter"
                  :errors="errors.patient_id"
                  field="name"
                  is-required
                ></app-autocomplete>
                <b-tooltip label="Cadastrar Paciente">
                  <b-button
                    @click="newPatient()"
                    type="is-text"
                    size="is-small"
                    icon-left="plus"
                  >
                    Novo paciente
                  </b-button>
                </b-tooltip>
              </div>
              <div class="col-3">
                <b-field
                  label="Data e Hora"
                  class="required"
                  :type="errors && errors.date ? 'is-danger' : null"
                  :message="errors.date"
                >
                  <b-datetimepicker
                    trap-focus
                    locale="pt-BR"
                    :editable="true"
                    icon="calendar"
                    horizontal-time-picker
                    v-model="schedule.date"
                    :min-datetime="minDate"
                    placeholder="Selecionar"
                    :date-formatter="bDateTimeFormatter"
                  >
                  </b-datetimepicker>
                </b-field>
              </div>
              <div class="col-3">
                <app-input
                  min="0"
                  is-required
                  type="number"
                  v-model="schedule.time"
                  label="Tempo da Consulta (min)"
                >
                </app-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <b-field label="Tipo da consulta">
                  <b-select
                    v-model="selectedAppointment"
                    placeholder="Selecione o tipo"
                    expanded
                  >
                    <option
                      v-for="appointment of appointments"
                      :key="appointment.id"
                      :value="appointment"
                    >
                      {{ appointment.name }}
                      <span v-if="appointment.type == 2">
                        ({{ appointment.value | currency }})
                      </span>
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div
                class="col-4"
                v-if="selectedAppointment && selectedAppointment.type !== 2"
              >
                <b-field label="Procedimento">
                  <b-select
                    expanded
                    icon="procedures"
                    placeholder="Selecionar"
                    v-model="schedule.procedure_id"
                    :loading="procedures === null"
                  >
                    <option
                      v-for="procedure in procedures || []"
                      :key="procedure.id"
                      :value="procedure.id"
                    >
                      {{ procedure.name }} ({{ procedure.value | currency }})
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  native-type="submit"
                  :loading="isLoading"
                  :disabled="isLoading || !canSave"
                  type="is-primary"
                >
                  Cadastrar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DoctorService from '@/services/doctor.service';
import PatientsService from '@/services/patients.service';
import SchedulesService from '@/services/schedules.service';
import PatientFormModal from '@/modals/PatientFormModal.vue';
import ProceduresService from '@/services/procedures.service';
import AppAutocomplete from '@/components/inputs/Autocomplete.vue';

import LinkPaymentOption from '@/modals/LinkPaymentOption.vue';

import * as moment from 'moment';
import BuefyMixin from '@/mixins/BuefyMixin';

export default {
  mixins: [BuefyMixin],
  components: {
    AppAutocomplete,
  },
  computed: {
    canSave() {
      return (
        this.schedule.patient_id && this.schedule.date && this.schedule.time
      );
    },
  },
  data: () => ({
    type: 1,
    errors: {},
    schedule: {
      procedure_id: '',
    },
    procedures: {},
    isLoading: false,
    appointments: [],
    minDate: new Date(),
    selectedAppointment: null,
  }),
  methods: {
    patientsGetter: PatientsService.search,
    patientSetter: PatientsService.getId,
    save(generateCheckouUrl) {
      this.isLoading = true;
      this.errors = {};

      const { patient_id, date, time, procedure_id } = this.schedule;
      const format = 'YYYY-MM-DD HH:mm:ss';
      const start = date ? moment(date).format(format) : '';
      const end = date ? moment(date).add(time, 'minute').format(format) : '';

      const data = {
        end: end,
        patient_id,
        start: start,
        generate_checkout_url: generateCheckouUrl,
        appointment_id: this.selectedAppointment.id,
        procedure_id: this.selectedAppointment.type == 2 ? null : procedure_id,
      };

      SchedulesService.store(data)
        .then(({ data }) => {
          this.$buefy.snackbar.open('Consulta agendada com sucesso!');

          !generateCheckouUrl && this.$router.go(-1);
          generateCheckouUrl && this.openCheckoutUrlDialog(data.checkout_url);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 422) {
            this.errors = data.message;
          } else
            this.$buefy.snackbar.open(
              data.message || 'Erro ao tentar cadastrar consulta.'
            );
        })
        .finally(() => (this.isLoading = false));
    },
    newPatient() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientFormModal,
        hasModalCard: true,
        trapFocus: true,
        width: '800',
      });
    },
    loadProcedures() {
      ProceduresService.get()
        .then(({ data }) => {
          this.procedures = [
            { id: '', name: 'Consulta', value: this.selectedAppointment.value },
            ...data,
          ];
        })
        .finally(() => (this.isLoading = false));
    },
    loadAppointment() {
      const doctorId = this.$store.getters.doctorId;
      DoctorService.getAppointmentInfo(doctorId)
        .then(({ data }) => {
          if (!data.length)
            this.$snackbar.open({
              message:
                'Por favor, antes de marcar uma consulta, cadastre seus valores',
            });

          const presential = data.filter(
            (appointment) => appointment.appointment_type_id == 1
          )[0];

          presential &&
            this.appointments.push({
              id: presential.id,
              name: 'Presencial',
              value: presential.value,
              type: presential.appointment_type_id,
            });

          const online = data.filter(
            (appointment) => appointment.appointment_type_id == 2
          )[0];

          online &&
            this.appointments.push({
              id: online.id,
              name: 'Teleconsulta',
              value: online.value,
              type: online.appointment_type_id,
            });

          this.selectedAppointment = this.appointments[0];
        })
        .finally(() => this.loadProcedures());
    },
    onSubmit() {
      this.$buefy.dialog.confirm({
        message: 'Gerar link para pagamento?',
        confirmText: 'Sim, gerar',
        cancelText: 'Não, o pagamento será externo',
        type: 'is-secondary',
        onConfirm: () => this.save(true),
        onCancel: () => this.save(false),
      });
    },
    openCheckoutUrlDialog(url) {
      this.$buefy.modal.open({
        parent: this,
        trapFocus: true,
        hasModalCard: true,
        component: LinkPaymentOption,
        props: { url },
        events: {
          close: () => this.$router.go(-1),
        },
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadAppointment();
  },
};
</script>
