import Vue from 'vue';
import * as moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATETIME_FORMAT = 'DD/MM/YYYY [às] HH:mm';

export default Vue.mixin({
  methods: {
    bDateFormatter(date) {
      return moment(date).format(DATE_FORMAT);
    },
    bDateRangeFormatter(dates) {
      return dates.map((date) => moment(date).format(DATE_FORMAT)).join(' - ');
    },
    bDateTimeFormatter(date) {
      return moment(date).format(DATETIME_FORMAT);
    },
  },
});
