<template>
  <div class="patient-form-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cadastrar Paciente</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="onSubmit">
          <div class="row mt-2">
            <div class="col-8">
              <app-input
                label="Nome"
                v-model="patient.name"
                :errors="errors.name"
                is-required
              >
              </app-input>
            </div>
            <div class="col-4">
              <b-field class="required" label="Data de nascimento">
                <b-datepicker
                  v-model="patient.birthday"
                  :years-range="[-100, 0]"
                  :append-to-body="true"
                  locale="pt-BR"
                  placeholder="Selecionar data..."
                  icon="calendar"
                  position="is-bottom-left"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <app-input
                label="CPF"
                v-model="patient.cpf"
                :errors="errors.cpf"
                :mask="MASKS.cpf"
                is-required
              ></app-input>
            </div>

            <div class="col-6">
              <app-input
                label="E-mail"
                v-model="patient.email"
                type="email"
                autocomplete="email"
                :errors="errors.email"
                is-required
              ></app-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <app-input
                label="Telefone"
                v-model="patient.phone"
                type="tel"
                :mask="MASKS.cellphone"
                :errors="errors.phone"
                is-required
              ></app-input>
            </div>
          </div>
          <hr />
          <div class="row mt-4">
            <div class="col-3">
              <app-input
                label="CEP"
                @input="getCepInfo"
                v-model="patient.cep"
                type="text"
                :mask="MASKS.cep"
                :errors="errors.cep"
                is-required
              ></app-input>
            </div>
            <div class="col-6">
              <app-input
                label="Rua"
                v-model="patient.street"
                type="text"
                :errors="errors.street"
                is-required
              ></app-input>
            </div>
            <div class="col-3">
              <app-input
                label="Número"
                v-model="patient.number"
                type="tel"
                :errors="errors.number"
                is-required
              ></app-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <app-input
                label="Complemento"
                v-model="patient.complement"
                type="text"
                :errors="errors.complement"
              ></app-input>
            </div>
            <div class="col-6">
              <app-input
                label="Bairro"
                v-model="patient.neighborhood"
                type="tel"
                :errors="errors.neighborhood"
                is-required
              ></app-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <app-autocomplete
                label="Cidade"
                ref="citySelector"
                v-model="patient.city_id"
                :value="patient.city_id"
                :getter="citiesGetter"
                :setter="citySetter"
                :errors="errors.city_id"
                field="name"
                is-required
              ></app-autocomplete>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button
          @click="onSubmit()"
          :loading="isSaving"
          :disabled="isSaving"
          type="is-primary"
        >
          Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import CitiesService from '@/services/cities.service';
import CepService from '@/services/cep.service';
import PatientsService from '@/services/patients.service';
import AppAutocomplete from '@/components/inputs/Autocomplete.vue';

import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';

export default {
  components: {
    AppAutocomplete,
  },
  data: () => ({
    MASKS: MASKS,
    patientId: null,
    isLoading: false,
    isSaving: false,
    patient: {
      birthday: null,
    },
    errors: {},
  }),
  methods: {
    citiesGetter: CitiesService.search,
    citySetter: CitiesService.getId,
    onSubmit() {
      this.isSaving = true;
      this.errors = {};

      const { cpf, cep, birthday } = this.patient;
      const data = {
        ...this.patient,
        cpf: cpf.replace(/\D/g, ''),
        cep: (cep || '').replace(/\D/g, ''),
        birthday: moment(birthday).format('YYYY-MM-DD'),
      };

      const promise = this.patientId
        ? PatientsService.update(this.patientId, data)
        : PatientsService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Paciente salvo com sucesso.');
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.isSaving = false;
          const { status, data } = response;

          if (status === 422) this.errors = data.message;
          else
            this.$buefy.snackbar.open(
              data.message || 'Paciente salvo com sucesso.'
            );
        });
    },
    getCepInfo(cep) {
      if (!cep) return;

      cep = cep.replace(/\D/g, '');
      if (cep.length < 8) return;

      CepService.getCepInfo(cep).then(({ data }) => {
        this.patient = {
          ...this.patient,
          street: data.logradouro,
          neighborhood: data.bairro,
        };

        this.$refs.citySelector.loadResourceById(data.city_id);
      });
    },
  },
};
</script>
