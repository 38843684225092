import Api from './api.service';

export default {
  get: (params = {}) => Api.get('patients', { params }),
  search: (term = '') =>
    Api.get(`patients?filter[name_email_document_or_phone]=${term}`),
  getId: (id) => Api.get(`patients/${id}`),
  store: (data) => Api.post('patients', data),
  update: (id, data) => Api.put(`patients/${id}`, data),
  destroy: (id) => Api.delete(`patients/${id}`),
  import: (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post('patients/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  export: (params) => {
    return Api.get('patients/export', { params });
  },
};
